// Handle url fragment with login redirection

const hash = window.location.hash;
const search = window.location.search;
// Django urlencode doesn't encode / character, need to match
const encodedHash = encodeURIComponent(hash).replace('%2F', '/')
if (hash && !search.includes(encodedHash)) {
  // Update document when fully loaded
  document.addEventListener('DOMContentLoaded', () => {
    const loginLinks = document.querySelectorAll(
      'a[href*="/login/?next="], a[href^="/guest-email"][href$="/?next="], a[href*="/logout/?next=/guest-email/"]'
    );
    const loginForms = document.querySelectorAll(
      'form[action*="/login/?next="], form[action*="/guest-email/?next="]'
    );
    const formInputs = document.querySelectorAll(
      'input[name="next"]'
    );

    const loginElements = [
      { attr: 'href', nodes: [...loginLinks] },
      { attr: 'action', nodes: [...loginForms] },
      { attr: 'value', nodes: [...formInputs] }
    ];

    loginElements.forEach(loginElement => {
      loginElement.nodes.forEach(node => {
        const originalAction = node.getAttribute(loginElement.attr);
        if (originalAction && !originalAction.endsWith(hash) && !originalAction.includes(encodedHash)) {
          node.setAttribute(loginElement.attr, originalAction + hash);
        }
      });
    });
  });
}
